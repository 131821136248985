import React, { useContext } from "react";
import "./sidebar.scss";
import Dashboard from "@mui/icons-material/Dashboard";
import {
  AccountCircle,
  ExitToAppOutlined,
  InsertChart,
  LocalShipping,
  Notifications,
  Person,
  SettingsApplications,
  PsychologyOutlined,
  SettingsSystemDaydream,
  Store,
  CreditCard,
} from "@mui/icons-material";
import { Link, NavLink } from "react-router-dom";
import { DarkModeContext } from "../../darkModeContext";

const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">lamadmin</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">Main</p>

          <NavLink
            to="/"
            style={{ textDecoration: "none" }}
            activeClassName="active"
          >
            <li>
              <Dashboard className="icon" />
              <span>Dashboard</span>
            </li>
          </NavLink>
          <p className="title">Lists</p>
          <NavLink
            to="/users"
            style={{ textDecoration: "none" }}
            activeClassName="active"
          >
            <li>
              <Person className="icon" />
              <span>Users</span>
            </li>
          </NavLink>
          <NavLink
            to="/products"
            style={{ textDecoration: "none" }}
            activeClassName="active"
          >
            <li>
              <Store className="icon" />
              <span>Products</span>
            </li>
          </NavLink>
          <li>
            <CreditCard className="icon" />
            <span>Orders</span>
          </li>
          <li>
            <LocalShipping className="icon" />
            <span>Delivery</span>
          </li>
          <p className="title">useful</p>
          <li>
            <InsertChart className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <Notifications className="icon" />
            <span>Notifications</span>
          </li>
          <p className="title">Service</p>
          <li>
            <SettingsSystemDaydream className="icon" />
            <span>System Health</span>
          </li>
          <li>
            <PsychologyOutlined className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <SettingsApplications className="icon" />
            <span>Settings</span>
          </li>
          <p className="title">user</p>
          <li>
            <AccountCircle className="icon" />
            <span>Profile</span>
          </li>
          <li>
            <ExitToAppOutlined className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
